export const SYSTEM_INSTABILITY =
  'Não foi possível realizar esta ação, tente novamente mais tarde.';
export const INVALID_EMAIL_OR_PASSWORD = 'E-mail ou senha inválidos.';
export const INVALID_EMAIL = 'E-mail inválido';
export const EMAIL_NOT_REGISTERED = 'Realize o cadastro.';
export const REGISTER_SUCCESS = 'Cadastro realizado com sucesso!';
export const PASSWORD_RECOVERY_SUCCESS = 'E-mail de recuperação de senha enviado com sucesso!';
export const PASSWORD_CHANGE_SUCCESS = 'Senha alterada com sucesso!';
export const INVALID_PASSWORD_CONFIRMATION =
  'A confirmação de senha deve ser igual à nova senha.';
export const CONFIRMATION_EMAIL_SUCCESS = 'E-mail de confirmação enviado com sucesso!';
export const EMAIL_FOUND = 'Este e-mail já está em uso.';
export const REQUIRED_FIELD = 'Campo obrigatório';
export const INVALID_PHONE = 'Telefone inválido';
export const INVALID_CPF = 'CPF inválido';
export const INVALID_CNPJ = 'CNPJ inválido';
export const INVALID_DATE = 'Data inválida';
export const EVENT_CREATED = 'Seu evento foi criado com sucesso!';
export const EVENT_EDITED = 'Evento editado com sucesso!';
export const EVENT_ENABLED = 'Evento ativado com sucesso!';
export const EVENT_DISABLED = 'Evento desativado com sucesso!';
export const EVENT_DELETED = 'Evento apagado com sucesso!';
export const EVENT_REMOVED_FROM_MY_EVENTS = 'Evento apagado com sucesso!';
export const UPLOAD_SUCCESS = 'Upload realizado com sucesso!';
export const UPLOAD_ERROR = 'O upload não foi concluído.';
export const PROFILE_EDIT_SUCCESS = 'Perfil alterado com sucesso.';
export const DELETE_ACCOUNT_SUCCESS = 'Conta excluída com sucesso.';
export const ADD_PHOTO_TO_CART_SUCCESS = 'Foto adicionada ao carrinho!';
export const REMOVE_PHOTO_FROM_CART_SUCCESS = 'Foto retirada do carrinho!';
export const DELETE_PHOTO_SUCCESS = 'Foto apagada com sucesso!';
export const DELETE_PHOTOS_SUCCESS = 'Fotos apagadas com sucesso!';
export const PASSWORD_MIN_LENGTH = 'Mínimo de 6 digitos';
export const INVALID_CEP = 'Mínimo de 6 digitos';
export const INVALID_CARD_NUMBER = 'Número de cartão inválido';
export const INVALID_EXPIRE_DATE = 'Data de validade inválida';
export const INVALID_CVV = 'CVV inválido';
export const INVALID_BANK_ACCOUNT = 'Número de conta inválido';
export const INVALID_BANK_AGENCY = 'Número de conta inválido';
export const INVALID_URL = 'URL inválida';
export const INVALID_NUMBER = 'Número inválido. Máximo 8 dígitos.';
export const WHATSAPP_MESSAGE =
  'Olá! Dei uma olhada no seu perfil na plataforma Rokkos e quero ajuda com o seguinte:';
export const COPY_URL_SUCCESS = 'URL copiada com sucesso!';
export const INVALID_USERNAME = 'Username indisponível';
