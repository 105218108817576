/* eslint-disable react/prop-types */
import { useFormik } from 'formik';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Avatar,
  Button,
  FileUploaderModal,
  Input,
  Loader,
  Modal,
  Select,
  Text,
} from '../../components';
import { PencilIcon, WalletIcon } from '../../components/svg';
import {
  addressFormDefinitions,
  photographerProfileFormDefinitions,
  requiredAddressFormDefinitions,
  shopperProfileFormDefinitions,
} from '../../constants/FormDefinitions';
import {
  DELETE_ACCOUNT_SUCCESS,
  PROFILE_EDIT_SUCCESS,
  SYSTEM_INSTABILITY,
} from '../../constants/Messages';
import * as Theme from '../../constants/Theme';
import { useStore } from '../../hooks';
import { compressImage, getAddressByZipCode } from '../../utils';
import ProfileFormItem from './ProfileFormItem';

const PrivateProfileForm = () => {
  const history = useHistory();
  const store = useStore();

  const user = store.UserStore.profile;

  const [editing, setEditing] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [deletingAccount, setDeletingAccount] = useState(false);
  const [requestingAddress, setRequestingAddress] = useState(false);
  const [avatarModalIsOpen, setAvatarModalIsOpen] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [mounted, setMounted] = useState(false);

  const getFormClassName = () => {
    let newClassName = 'profile__form surface-7';
    if (editing) newClassName += ' profile__form--editing';
    return newClassName;
  };

  const getAddress = async (zipCode) => {
    try {
      setRequestingAddress(true);

      const address = await getAddressByZipCode(zipCode);

      if (address) {
        formik.setValues((values) => ({ ...values, ...address }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setRequestingAddress(false);
    }
  };

  const onSubmit = async (formValues) => {
    const { person_type, cpf, cnpj } = formValues;

    const payload = { ...formValues };

    if (person_type === 'juridica') payload.cpf_cnpj = cnpj;
    else payload.cpf_cnpj = cpf;

    const response = await flowResult(store.UserStore.editProfile(payload));

    if (response?.error?.status) {
      switch (response?.error?.status) {
        default: {
          toast.error(SYSTEM_INSTABILITY);
          break;
        }
      }
      return;
    }

    toast.success(PROFILE_EDIT_SUCCESS);

    setEditing(false);
  };

  let validationSchema = {};

  let initialValues = {};

  if (user) {
    if (user?.type === 1) {
      initialValues = {
        ...requiredAddressFormDefinitions.initialValues,
        ...photographerProfileFormDefinitions.initialValues,
      };

      validationSchema = requiredAddressFormDefinitions.validationSchema.concat(
        photographerProfileFormDefinitions.validationSchema,
      );
    } else {
      initialValues = {
        ...addressFormDefinitions.initialValues,
        ...shopperProfileFormDefinitions.initialValues,
      };

      validationSchema = addressFormDefinitions.validationSchema.concat(
        shopperProfileFormDefinitions.validationSchema,
      );
    }

    initialValues = {
      ...initialValues,
      ...user,
      ...user.address,
      zip: String(user.address.zip).replace('-', ''),
      number: String(user.address.number),
      phone: `${user.phone_prefix}${user.phone}`,
      person_type: user?.cpf_cnpj?.length > 11 ? 'juridica' : 'fisica',
    };

    if (user?.cpf_cnpj) {
      if (user?.cpf_cnpj?.length > 11) {
        initialValues.cnpj = user.cpf_cnpj;
      } else {
        initialValues.cpf = user.cpf_cnpj;
      }
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const deleteAccount = async () => {
    try {
      setDeletingAccount(true);

      const response = await flowResult(store.UserStore.deleteAccount());

      if (response?.error) {
        switch (response?.error?.status) {
          default:
            toast.error(SYSTEM_INSTABILITY);
            break;
        }
        return;
      }

      toast.success(DELETE_ACCOUNT_SUCCESS);
    } catch (error) {
      console.log(error);
    } finally {
      setDeletingAccount(false);
    }
  };

  const handleAvatarChange = async (files) => {
    if (files?.length) {
      const compressedImage = await compressImage(files[0]);

      if (compressedImage) {
        formik.setFieldValue(
          'avatar',
          new File([compressedImage], compressedImage.name, {
            type: compressedImage.type,
          }),
        );
      }
    }
  };

  const handlePersonTypeChange = (event) => {
    const person_type = event.target.value;
    formik.setFieldValue('person_type', person_type);

    if (person_type === 'juridica' && String(user?.cpf_cnpj).length > 11) {
      formik.setFieldValue('cnpj', user.cpf_cnpj);
      formik.setFieldValue('cpf', '');

      return;
    }

    formik.setFieldValue('cpf', user.cpf_cnpj);
    formik.setFieldValue('cnpj', '');
  };

  useEffect(() => {
    if (formik.values?.zip?.length >= 8 && mounted) {
      getAddress(formik.values.zip);
    }
  }, [formik.values.zip]);

  useEffect(() => {
    if (formik.values?.avatar) {
      if (formik.values.avatar instanceof File) {
        const newAvatarPreview = URL.createObjectURL(formik.values.avatar);
        setAvatarPreview(newAvatarPreview);
        return () => {
          URL.revokeObjectURL(newAvatarPreview);
        };
      } else if (typeof formik.values.avatar === 'string') {
        setAvatarPreview(formik.values.avatar);
      }
    }
  }, [formik.values.avatar]);

  useEffect(() => {
    if (formik.values?.avatar_url?.length) setAvatarPreview(formik.values.avatar_url);
    setMounted(true);
  }, []);

  return (
    <main
      style={{
        pointerEvents: formik.isSubmitting ? 'none' : 'initial',
      }}
    >
      <form className={getFormClassName()} noValidate onSubmit={formik.handleSubmit}>
        {!editing && (
          <div className='profile__form__actions'>
            <Button
              startIcon={<PencilIcon color={Theme.primary} />}
              onClick={() => setEditing(true)}
            >
              Editar dados cadastrais
            </Button>
          </div>
        )}

        <div className='profile__form__avatar'>
          <Button
            disabled={!editing}
            className='profile__form__avatar__action'
            onClick={() => setAvatarModalIsOpen(true)}
          >
            <Avatar size='extra-large' image={avatarPreview} />
          </Button>
          {editing && (
            <>
              <Button
                className='profile__form__avatar__button'
                onClick={() => setAvatarModalIsOpen(true)}
              >
                <Text variant='subtitle-1' color={Theme.primary}>
                  Alterar foto de perfil
                </Text>
              </Button>
              <FileUploaderModal
                modalProps={{
                  open: avatarModalIsOpen,
                  btCancelText: 'Cancelar',
                  onCancel: () => {
                    setAvatarModalIsOpen(false);
                    // setAvatarPreview(null);
                    // formik.setFieldValue("avatar", null);
                  },
                  onConfirm: () => {
                    setAvatarModalIsOpen(false);
                  },
                  btCancelVariant: 'outlined',
                  btConfirmVariant: 'contained',
                }}
                title='Foto de perfil'
                description='Defina a sua foto de perfil.'
                preview={avatarPreview}
                onChange={handleAvatarChange}
              />
            </>
          )}
        </div>

        <section className='profile__form__section'>
          <div className='profile__form__title'>
            <Text variant='h5' color={Theme.primary}>
              Dados pessoais
            </Text>
          </div>

          {editing ? (
            <>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='Nome'
                  fullWidth
                  id='name'
                  value={formik.values.name}
                  error={formik.touched.name && !!formik.errors.name}
                  helperText={formik.touched.name && formik.errors.name}
                  onChange={formik.handleChange}
                />
              </div>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='Telefone'
                  fullWidth
                  id='phone'
                  value={formik.values.phone}
                  error={formik.touched.phone && !!formik.errors.phone}
                  helperText={formik.touched.phone && formik.errors.phone}
                  onChange={formik.handleChange}
                  mask='phone'
                />
              </div>
              <div className='profile__form__input'>
                <Select
                  variant='filled'
                  fullWidth
                  id='person_type'
                  placeholder='Tipo de pessoa'
                  value={formik.values.person_type}
                  error={formik.touched.person_type && !!formik.errors.person_type}
                  helperText={formik.touched.person_type && formik.errors.person_type}
                  onChange={handlePersonTypeChange}
                  options={[
                    {
                      value: 'fisica',
                      label: 'Pessoa Física',
                    },
                    {
                      value: 'juridica',
                      label: 'Pessoa Jurídica',
                    },
                  ]}
                />
              </div>
              <div className='profile__form__input'>
                {formik.values.person_type === 'juridica' ? (
                  <Input
                    variant='filled'
                    placeholder='CNPJ'
                    fullWidth
                    id='cnpj'
                    value={String(formik.values.cnpj)}
                    error={formik.touched.cnpj && !!formik.errors.cnpj}
                    helperText={formik.touched.cnpj && formik.errors.cnpj}
                    onChange={formik.handleChange}
                    mask='cnpj'
                  />
                ) : (
                  <Input
                    variant='filled'
                    placeholder='CPF'
                    fullWidth
                    id='cpf'
                    value={String(formik.values.cpf)}
                    error={formik.touched.cpf && !!formik.errors.cpf}
                    helperText={formik.touched.cpf && formik.errors.cpf}
                    onChange={formik.handleChange}
                    mask='cpf'
                  />
                )}
              </div>
            </>
          ) : (
            <>
              <ProfileFormItem label='Nome' value={user?.name} />
              <ProfileFormItem
                label='Telefone'
                value={
                  user?.phone && user.phone_prefix ? (
                    <NumberFormat
                      format='(##) #####-####'
                      isNumericString
                      value={`${user.phone_prefix}${user.phone}`}
                      displayType='text'
                    />
                  ) : null
                }
              />
              <ProfileFormItem label='E-mail' value={user?.email} />
              <ProfileFormItem
                label={user?.cpf_cnpj?.length > 11 ? 'CNPJ' : 'CPF'}
                value={
                  !!user?.cpf_cnpj?.length && (
                    <NumberFormat
                      format={
                        user?.cpf_cnpj?.length > 11 ? '##.###.###/####-##' : '###.###.###-##'
                      }
                      isNumericString
                      value={user?.cpf_cnpj}
                      displayType='text'
                    />
                  )
                }
              />
            </>
          )}
        </section>

        <section className='profile__form__section'>
          <div className='profile__form__title'>
            <Text variant='h5' color={Theme.primary}>
              Endereço
            </Text>
          </div>

          {editing ? (
            <>
              <div className='profile__form__input'>
                <Input
                  variant='filled'
                  placeholder='CEP'
                  fullWidth
                  id='zip'
                  value={formik.values.zip}
                  error={formik.touched.zip && !!formik.errors.zip}
                  helperText={formik.touched.zip && formik.errors.zip}
                  disabled={requestingAddress}
                  onChange={formik.handleChange}
                  endAdornment={requestingAddress ? <Loader /> : null}
                  mask='cep'
                />
              </div>

              <div className='profile__form__row'>
                <div className='profile__form__input street'>
                  <Input
                    variant='filled'
                    placeholder='Rua'
                    fullWidth
                    id='street'
                    value={formik.values.street}
                    error={formik.touched.street && !!formik.errors.street}
                    helperText={formik.touched.street && formik.errors.street}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className='profile__form__input number'>
                  <Input
                    variant='filled'
                    placeholder='Número'
                    fullWidth
                    id='number'
                    value={String(formik.values?.number)}
                    error={formik.touched.number && !!formik.errors.number}
                    helperText={formik.touched.number && formik.errors.number}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className='profile__form__row'>
                <div className='profile__form__input complement'>
                  <Input
                    variant='filled'
                    placeholder='Complemento'
                    fullWidth
                    id='complement'
                    value={formik.values.complement}
                    error={formik.touched.complement && !!formik.errors.complement}
                    helperText={formik.touched.complement && formik.errors.complement}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className='profile__form__input district'>
                  <Input
                    variant='filled'
                    placeholder='Bairro'
                    fullWidth
                    id='district'
                    value={formik.values.district}
                    error={formik.touched.district && !!formik.errors.district}
                    helperText={formik.touched.district && formik.errors.district}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className='profile__form__row'>
                <div className='profile__form__input city'>
                  <Input
                    variant='filled'
                    placeholder='Cidade'
                    fullWidth
                    id='city'
                    value={formik.values.city}
                    error={formik.touched.city && !!formik.errors.city}
                    helperText={formik.touched.city && formik.errors.city}
                    onChange={formik.handleChange}
                  />
                </div>
                <div className='profile__form__input state'>
                  <Input
                    variant='filled'
                    placeholder='Estado'
                    fullWidth
                    id='state'
                    value={formik.values.state}
                    error={formik.touched.state && !!formik.errors.state}
                    helperText={formik.touched.state && formik.errors.state}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <ProfileFormItem label='CEP' value={user?.address.zip} />
              <ProfileFormItem label='Rua' value={user?.address.street} />
              <ProfileFormItem label='Número' value={user?.address.number} />
              <ProfileFormItem label='Complemento' value={user?.address.complement} />
              <ProfileFormItem label='Bairro' value={user?.address.district} />
              <ProfileFormItem label='Cidade' value={user?.address.city} />
              <ProfileFormItem label='Estado' value={user?.address.state} />
            </>
          )}
        </section>

        {user?.type === 1 &&
          (user?.bank_data?.gateway_account_validated ? (
            editing ? (
              // <section className='profile__form__section bank-data'>
              //   <div className='profile__form__title'>
              //     <Text variant='h5' color={Theme.primary}>
              //       Dados bancários
              //     </Text>
              //   </div>

              //   <div className='bank-data__icon'>
              //     <WalletIcon />
              //   </div>

              //   <div className='bank-data__description'>
              //     <Text variant='h6'>
              //       Para alterar seus dados bancários acesse este{' '}
              //       <a href='https://rokkos.club/verificacao/' target='_blank' rel='noreferrer'>
              //         link
              //       </a>
              //       .
              //     </Text>
              //   </div>
              // </section>
              <div />
            ) : (
              <section className='profile__form__section'>
                <div className='profile__form__title'>
                  <Text variant='h5' color={Theme.primary}>
                    Dados bancários
                  </Text>
                </div>

                <div className='bank-data__content'>
                  {!!user?.bank_data?.gateway_account_bank?.length && (
                    <ProfileFormItem
                      label='Banco'
                      value={user.bank_data.gateway_account_bank}
                    />
                  )}
                  {!!user?.bank_data?.gateway_account_bank_ag?.length && (
                    <ProfileFormItem
                      label='Agência'
                      value={user.bank_data.gateway_account_bank_ag}
                    />
                  )}
                  {!!user?.bank_data?.gateway_account_bank_account_type?.length && (
                    <ProfileFormItem
                      label='Tipo de conta'
                      value={user.bank_data.gateway_account_bank_account_type}
                    />
                  )}
                  {!!user?.bank_data?.gateway_account_bank_cc?.length && (
                    <ProfileFormItem
                      label='Número da conta'
                      value={user.bank_data.gateway_account_bank_cc}
                    />
                  )}
                </div>
              </section>
            )
          ) : (
            <section className='profile__form__section bank-data'>
              <div className='profile__form__title'>
                <Text variant='h5' color={Theme.primary}>
                  Dados bancários
                </Text>
              </div>

              <div className='bank-data__icon'>
                <WalletIcon />
              </div>

              <div className='bank-data__description'>
                <Text variant='h6'>
                  Para o cadastramento dos seus dados bancários envie os seguintes documentos:
                </Text>
              </div>

              <div className='bank-data__list'>
                <div className='bank-data__list__item'>
                  <Text variant='subtitle-1'>- CNH ou documento oficial com foto;</Text>
                </div>
                <div className='bank-data__list__item'>
                  <Text variant='subtitle-1'>
                    - Cartão CNPJ (apenas se optar por pessoa jurídica);
                  </Text>
                </div>
                <div className='bank-data__list__item'>
                  <Text variant='subtitle-1'>
                    - Print do seu app do banco ou o cabeçalho do extrato bancário contendo as
                    seguintes informações: banco, seu nome, agência, conta e tipo de conta;
                  </Text>
                </div>
              </div>

              <div className='bank-data__link'>
                <Text variant='h6'>
                  Os documentos devem ser enviados neste{' '}
                  <a href='https://rokkos.club/verificacao/' target='_blank' rel='noreferrer'>
                    link
                  </a>
                  .
                </Text>
              </div>
            </section>
          ))}

        {editing && (
          <section>
            <Button
              variant='contained'
              type='submit'
              className='profile__form__bt-submit'
              loading={formik.isSubmitting}
            >
              Salvar Alterações
            </Button>
          </section>
        )}

        <section>
          <div className='profile__form__title'>
            <Text variant='h5' color={Theme.primary}>
              Alterar senha
            </Text>
          </div>
          <div className='profile__form__description'>
            <Text variant='h6'>Quero alterar minha senha.</Text>
          </div>
          <div className='profile__form__button'>
            <Button variant='outlined' onClick={() => history.push(`/change-password`)}>
              Alterar senha
            </Button>
          </div>
        </section>

        <section>
          <div className='profile__form__title'>
            <Text variant='h5' color={Theme.primary}>
              Excluir conta
            </Text>
          </div>
          <div className='profile__form__description'>
            <Text variant='h6'>Quero excluir minha conta.</Text>
          </div>
          <div className='profile__form__button'>
            <Button
              variant='outlined'
              loading={deletingAccount}
              onClick={() => setDeleteModalIsOpen(true)}
            >
              Excluir conta
            </Button>
            <Modal
              open={deleteModalIsOpen}
              btCancelText='Voltar'
              btConfirmText='Concluir'
              btCancelColor='light'
              onCancel={() => setDeleteModalIsOpen(false)}
              onConfirm={() => {
                setDeleteModalIsOpen(false);
                deleteAccount();
              }}
            >
              <Text variant='h6' className='modal-title'>
                Você tem certeza?
              </Text>
              <Text variant='body-1' color={Theme.light60}>
                Sentimos muito que você queira deixar a Rokkos. Uma vez que a conta é excluída,
                não é possível recuperá-la e todos os dados serão perdidos.
              </Text>
            </Modal>
          </div>
        </section>
      </form>
    </main>
  );
};

export default observer(PrivateProfileForm);
